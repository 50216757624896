import { upperCaseFirstLetter } from "./upperCaseFirstLetter";

export interface LocationParams {
  city?: string | null;
  state?: string | null;
  country?: string | null;
}

export function formatLocation(options: LocationParams): string {
  const { city, state, country } = options;
  let output = upperCaseFirstLetter(city ? city.trim() : undefined);
  if (state) {
    const trimmedS = state.trim();
    // upper case Alpha 2 , else just first letter
    const formattedState =
      state.length === 2
        ? trimmedS.toUpperCase()
        : upperCaseFirstLetter(trimmedS);

    output = output ? `${output}, ${formattedState}` : `${formattedState}`;
  }
  if (country) {
    const trimmedC = country.trim();
    output = output ? `${output}, ${trimmedC}` : `${trimmedC}`;
  }
  return output;
}
