export function upperCaseFirstLetter(stringIn?: string): string {
  if (!stringIn || stringIn.length === 0) {
    return "";
  }

  const hasDashes = stringIn.indexOf("_") !== -1;
  const stringArr = hasDashes ? stringIn.split("_") : stringIn.split(" ");
  const output = stringArr.map((currString: string, index: number) => {
    const lowerName = currString?.toLowerCase();
    let formatted =
      lowerName.charAt(0).toUpperCase() + lowerName.slice(1).toLowerCase();
    if (index !== 0) {
      formatted = " " + formatted;
    }
    return formatted;
  });
  return output.join(" ");
}

// quick and dirty, kinda assuming theirs not many ships with above 10 roman numerals
const numeralsArr = [
  "i",
  "ii",
  "iii",
  "iv",
  "v",
  "vi",
  "vii",
  "viii",
  "ix",
  "x",
];

export function upperCaseRomanNumerals(stringIn: string): string {
  if (!stringIn || stringIn.length === 0) {
    return "";
  }

  const stringArr = stringIn.split(" ");
  const output = stringArr.map((currString: string) => {
    const lowerName = currString?.toLowerCase();
    if (numeralsArr.includes(lowerName)) {
      const formatted = lowerName.toUpperCase();
      return formatted;
    }
    return currString;
  });
  return output.join(" ");
}
