import React, {
  ReactElement,
  CSSProperties,
  useState,
  MouseEventHandler,
} from "react";
import styled from "styled-components";
import BounceLoader from "react-spinners/BounceLoader";

import {
  ButtonTypes,
  ButtonStyles,
  ButtonStylesHover,
} from "lib/design-system/buttonTypes";
import { PrimaryColorPalette } from "lib/styles/colors";

export { ButtonTypes };

interface ButtonProps {
  variant: ButtonTypes;
  style?: CSSProperties;
  children: string;
  disabled: boolean;
  isLoading: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  type?: "button" | "submit" | "reset" | undefined;
}

const StyledButton = styled.button`
  font-family: SFProDisplay;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.75px;
  line-height: 1rem;
  border-radius: 8px;
  height: 44px;
  border: 0px;
`;

const TextButton = styled.button`
  font-family: SFProDisplay;
  text-decoration: underline;
`;

export const Button = ({
  variant = ButtonTypes.PRIMARY,
  disabled,
  isLoading,
  children,
  style,
  onClick,
  type,
}: ButtonProps): ReactElement => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const handleMouseOver = () => setIsHovering(true);
  const handleMouseOut = () => setIsHovering(false);
  const combinedStyles = aggregateStyles({
    disabled,
    isHovering,
    style,
    type: variant,
  });

  if (variant === ButtonTypes.TEXT_BUTTON) {
    return (
      <TextButton
        style={combinedStyles}
        disabled={disabled}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={onClick}
        type={type}
      >
        {children}
      </TextButton>
    );
  }

  return (
    <StyledButton
      style={combinedStyles}
      disabled={disabled}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={onClick}
      type={type}
    >
      {/* NOTE: temporary loader until a Designer gives us a Loader SVG / lottie */}
      {isLoading ? (
        <BounceLoader size="14px" color={PrimaryColorPalette.MAIN_6} />
      ) : (
        // <CircleLoader size='14px' color={PrimaryColorPalette.MAIN_6} />
        children
      )}
    </StyledButton>
  );
};

export default Button;

/**
 * Combine styles based on the based text type and any other one off style attributes
 */
function aggregateStyles(options: {
  type: ButtonTypes;
  isHovering: boolean;
  disabled: boolean;
  style?: CSSProperties;
}): CSSProperties {
  const { type, isHovering, disabled, style } = options;

  const isdisabled: CSSProperties = {
    opacity: "40%",
  };

  return {
    ...(disabled && { ...isdisabled }),
    ...(ButtonStyles[type] as CSSProperties),
    ...((isHovering && { ...ButtonStylesHover[type] }) as CSSProperties),
    ...(Boolean(style) && { ...style }),
  };
}
