import { CSSProperties } from "react";
import {
  BackgroundColorPalette,
  BrandColorPalette,
  PrimaryColorPalette,
  StatusColorPalette,
  TextAndShapesPalette,
} from "lib/styles/colors";

export enum ButtonTypes {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
  DELETE = "DELETE",
  TEXT_BUTTON = "TEXT_BUTTON",
}

export type CustomButtonStyles = { [key in ButtonTypes]: CSSProperties };

const DEFAULT_HOVER_STYLES: CSSProperties = {
  cursor: "pointer",
};

export const ButtonStylesHover: CustomButtonStyles = {
  [ButtonTypes.PRIMARY]: {
    ...DEFAULT_HOVER_STYLES,
    background: BrandColorPalette.BRANDING,
  },
  [ButtonTypes.SECONDARY]: {
    ...DEFAULT_HOVER_STYLES,
    border: `2px solid ${BrandColorPalette.BRANDING}`,
    color: BrandColorPalette.BRANDING,
  },
  [ButtonTypes.TERTIARY]: {
    ...DEFAULT_HOVER_STYLES,
  },
  [ButtonTypes.DELETE]: {
    ...DEFAULT_HOVER_STYLES,
  },
  [ButtonTypes.TEXT_BUTTON]: {
    ...DEFAULT_HOVER_STYLES,
    fontFamily: "SFProDisplay",
    fontSize: 16,
    textAlign: "center",
    textDecoration: "underline",
  },
};

export const ButtonStyles: CustomButtonStyles = {
  [ButtonTypes.PRIMARY]: {
    background: BrandColorPalette.BRANDING,
    color: PrimaryColorPalette.MAIN_6,
    textTransform: "uppercase",
    width: "400px",
  },
  [ButtonTypes.SECONDARY]: {
    background: "transparent",
    border: `2px solid ${BrandColorPalette.BRANDING}`,
    color: BrandColorPalette.BRANDING,
    textTransform: "uppercase",
    width: "104px",
  },
  [ButtonTypes.TERTIARY]: {
    background: BackgroundColorPalette.BASIC_DECORATION_GRAY,
    color: PrimaryColorPalette.MAIN_0,
    textTransform: "uppercase",
    width: "104px",
  },
  [ButtonTypes.DELETE]: {
    background: StatusColorPalette.DANGER_BACKGROUND,
    color: StatusColorPalette.DANGER_STATUS,
    width: "104px",
  },
  [ButtonTypes.TEXT_BUTTON]: {
    background: "transparent",
    color: TextAndShapesPalette.INPUT,
  },
};
